import React from "react";
import QuantityStepper from "@ingka/quantity-stepper";

import "@ingka/product-identifier/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/quantity-stepper/dist/style.css";
import "@ingka/svg-icon/dist/style.css";

interface GeneralQuantityStepperProps {
  key: string;
  id: string;
  small: true | false;
  value: number;
  ariaDescriptionId: string;
  ariaDescription: string;
  onQuantityChange: (quantity: number) => void;
}

export const GeneralQuantityStepper = ({
  key,
  id,
  small,
  value,
  ariaDescriptionId,
  ariaDescription,
  onQuantityChange,
}: GeneralQuantityStepperProps) => {
  return (
    <QuantityStepper
      key={key}
      id={id}
      minVal={1}
      maxVal={1000}
      small={small}
      defaultValue={value}
      ariaDescribedById={ariaDescriptionId}
      ariaDescribedBy={ariaDescription}
      onQuantityChange={onQuantityChange}
    />
  );
};
