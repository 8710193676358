import React, { useCallback, useMemo, useState } from "react";
import { TableBody } from "@ingka/table";
import Switch from "@ingka/switch";
import { SkapaPromptModal, ModalOption } from "../../../modals/SkapaSheetModal";
import { useFetchUsers } from "../hooks/useFetchUsers";
import { CreateUserType, User, UserSettings } from "../types/users.types";
import dayjs from "dayjs";
import { getRoleName, getStoreName } from "../../../helpers/helpers";
import useTeams from "../../teams/hooks/useTeams";
import useStores from "../../stores/hooks/useStores";
import Button from "@ingka/button";
import useUpdateUser from "../hooks/useUpdateUser";
import { UserModalChildren } from "./users";
import { Teams } from "../../teams/types/Teams.types";
import { USER_ROLES, UserRole } from "../../../types/roles.types";
import { MessageModal } from "../../../modals/MessageModal";

export const UsersTable = ({ country, store, role, searchValue, sortField, sortDirection }: UserSettings) => {
  const [editModal, setEditModal] = useState(false);
  const { data: teams } = useTeams();
  const { data: stores } = useStores();
  const [userState, setUserState] = useState<CreateUserType | undefined>(undefined);
  const [activeSwitch, setActiveSwitch] = useState(userState?.status === "ACTIVE");
  const [activeSwitchModal, setActiveSwitchModal] = useState(false);
  const { data: users } = useFetchUsers(country, store, role);
  const updateUserMutation = useUpdateUser(userState);
  const [successMessage, setSuccessMessage] = useState<string>("");

  const switchUser = useCallback(() => {
    if (userState) {
      userState.status = userState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
      void updateUserMutation.mutateAsync();
      setActiveSwitchModal(false);
    }
  }, [userState, updateUserMutation]);

  const filteredUsers = useMemo(() => {
    return users?.filter((user) =>
      searchValue ? user?.fullName?.toLowerCase().includes(searchValue.toLowerCase()) : true
    );
  }, [users, searchValue]);

  const compareDates = useCallback((aDate: string, bDate: string, sortDirection: string) => {
    const aTime = new Date(aDate).getTime();
    const bTime = new Date(bDate).getTime();

    if (aTime < bTime) return sortDirection === "asc" ? -1 : 1;
    if (aTime > bTime) return sortDirection === "asc" ? 1 : -1;
    return 0;
  }, []);

  const getTeamName = useCallback((teamId: string, teams: Teams): string => {
    return teams?.find((team) => team.id === teamId)?.name ?? "";
  }, []);

  const sortUsers = useCallback(
    (filteredUsers: User[], sortField: keyof User, sortDirection: string) => {
      return [...filteredUsers].sort((a, b) => {
        let aValue = a[sortField] ?? "";
        let bValue = b[sortField] ?? "";

        switch (sortField) {
          case "teamId": {
            if (teams) {
              aValue = getTeamName(a.teamId, teams);
              bValue = getTeamName(b.teamId, teams);
            }
            break;
          }
          case "updatedAt": {
            return compareDates(aValue as string, bValue as string, sortDirection);
          }
          case "roleId": {
            const aIndex = USER_ROLES.indexOf(aValue as UserRole);
            const bIndex = USER_ROLES.indexOf(bValue as UserRole);
            return sortDirection === "asc" ? aIndex - bIndex : bIndex - aIndex;
          }
          default: {
            const aString = String(aValue);
            const bString = String(bValue);
            return sortDirection === "asc" ? aString.localeCompare(bString) : bString.localeCompare(aString);
          }
        }

        const aString = String(aValue);
        const bString = String(bValue);
        return sortDirection === "asc" ? aString.localeCompare(bString) : bString.localeCompare(aString);
      });
    },
    [teams, compareDates, getTeamName]
  );

  const sortedUsers = useMemo(
    () => sortUsers(filteredUsers ?? [], sortField ?? "fullName", sortDirection ?? "asc"),
    [filteredUsers, sortField, sortDirection, sortUsers]
  );

  const handleRowClick = useCallback((user: User) => {
    setUserState({
      teamId: user.teamId,
      storeId: user.storeId,
      roleId: user.roleId,
      status: user.status,
      countryId: user.countryId,
      email: user.email,
      fullName: user?.fullName,
      fixaUid: user.fixaUid,
    } as typeof userState);
    setEditModal(true);
  }, []);

  const handleSwitchChange = useCallback(
    (user: User) => {
      setActiveSwitch(!activeSwitch);
      setUserState({
        fullName: user.fullName,
        fixaUid: user.fixaUid,
        status: user.status,
      } as typeof userState);
    },
    [activeSwitch]
  );

  return (
    <>
      <TableBody style={{ backgroundColor: "white", cursor: "pointer" }}>
        {sortedUsers?.map((user) => (
          <tr
            onClick={() => {
              handleRowClick(user);
            }}
            key={user?.uid + user.teamId}
          >
            <td>{user.fullName}</td>
            <td>{teams?.find((team) => team.id === user.teamId)?.name}</td>
            <td>{getStoreName(stores ?? [], user.storeId) ?? ""}</td>
            <td>{getRoleName(user.roleId)}</td>
            <td>{dayjs(user.updatedAt).format("YYYY-MM-DD")}</td>
            <td>
              <Switch
                id={user.fullName ?? ""}
                value={activeSwitch ? "INACTIVE" : "ACTIVE"}
                checked={user.status === "ACTIVE"}
                onChange={() => {
                  handleSwitchChange(user);
                }}
                onClick={(event) => {
                  setActiveSwitchModal(true);
                  event.stopPropagation();
                }}
              />
            </td>
          </tr>
        ))}
      </TableBody>

      {editModal && (
        <SkapaPromptModal
          buttonText={"Edit user"}
          description={userState?.email ?? ""}
          option={ModalOption.EDIT}
          onClose={() => {
            setEditModal(false);
          }}
        >
          {userState && (
            <UserModalChildren
              country={country}
              user={userState}
              setUserHandler={setUserState}
              modalType={ModalOption.EDIT}
              setSuccessMessage={setSuccessMessage}
            />
          )}
        </SkapaPromptModal>
      )}
      {activeSwitchModal && userState && (
        <SkapaPromptModal
          buttonText={"Change status"}
          description={`Are you sure you want to ${userState.status === "ACTIVE" ? "Inactive" : "Active"} ${
            userState.fullName
          }?`}
          option={ModalOption.ACTIVE_CLICK}
          onClose={() => {
            setEditModal(false);
            setActiveSwitchModal(false);
          }}
          children={<Button type="emphasised" text={"Change status"} onClick={() => switchUser()} />}
          user={userState}
        />
      )}
      {filteredUsers?.length === 0 && <div style={{ width: "100%", margin: "auto" }}>No users found</div>}
      {successMessage && <MessageModal message={successMessage} closeModal={() => setSuccessMessage("")} />}
    </>
  );
};
