import React, { useState } from "react";
import { TableBody } from "@ingka/table";
import { SkapaPromptModal, ModalOption } from "../../../modals/SkapaSheetModal";
import { CreateStoreContactData, StoreContact } from "../types/StoreContacts.types";
import { getStoreName } from "../../../helpers/helpers";
import useStoreContacts from "../hooks/useStoreContacts";
import useStores from "../../stores/hooks/useStores";
import { EditStoreContactsForm } from "../organisms/EditStoreContactForm";
import { MessageModal } from "../../../modals/MessageModal";

export const StoreContactsBody = ({ storeId }: { storeId: string }) => {
  const { data: storeContacts } = useStoreContacts(storeId);
  const { data: stores } = useStores();
  const [editModal, setEditModal] = useState(false);
  const [contactClicked, setContactClicked] = useState<StoreContact>({} as StoreContact);
  const [, setUpdatedStoreContact] = useState<CreateStoreContactData>({} as CreateStoreContactData);
  const [successMessage, setSuccessMessage] = useState<string>("");

  return (
    <>
      <TableBody style={{ backgroundColor: "white" }}>
        {storeContacts?.map((storeContact: StoreContact) => (
          <tr
            key={storeContact.id}
            onClick={() => {
              setContactClicked(storeContact);
              setEditModal(true);
            }}
          >
            <td>{storeContact.name}</td>
            <td>{storeContact.phoneNumber}</td>
            <td>{getStoreName(stores ?? [], storeContact.storeId)}</td>
            <td>{storeContact.teamName}</td>
          </tr>
        ))}
      </TableBody>
      {editModal && (
        <SkapaPromptModal
          description=""
          buttonText="Edit store contact"
          option={ModalOption.EDIT}
          onClose={() => setEditModal(false)}
          children={
            <EditStoreContactsForm
              storeContact={contactClicked}
              setUpdatedStoreContact={setUpdatedStoreContact}
              setEditModal={setEditModal}
              setSuccessMessage={setSuccessMessage}
            />
          }
        />
      )}
      {successMessage && <MessageModal message={successMessage} closeModal={() => setSuccessMessage("")} />};
    </>
  );
};
