import React from "react";
import Table, { TableBody } from "@ingka/table";
import { RoomSettingsArticlesTableHeader } from "../molecules/RoomSettingsArticlesTableHeader";
import { Article, Articles, ProductArticleIdAndQuantity } from "../types/Articles.types";
import { ArticleRow } from "./ArticleRow";
import useRoom from "../hooks/rooms/useRoom";
import { sortArticles } from "./ArticleRow.helper";
import { useArticlesInfo } from "../hooks/articles/useArticlesInfo";
import { updateArticle } from "../services/articles.service";
import { transformArticlesInfos } from "../helpers/transform.articles.helper";
import { useEDSData } from "../hooks/articles/useEDSData";
import { useParams } from "react-router-dom";
import { useSalesStopData } from "../hooks/articles/useSalesStopData";
import { useTaskLinksByRoom } from "../hooks/taskLinks/useTaskLinksByRoom";

interface RoomSettingsArticlesTableProps {
  articles: Articles;
  articleUpdated: (articleId: string, isDelete: boolean, errorMessage: string | undefined) => Promise<void>;
  updateArticles: () => Promise<void>;
  groupId: string;
  showHeaders?: boolean;
}

export const RoomSettingsArticlesTable = ({
  articles,
  articleUpdated,
  updateArticles: handleUpdateArticles,
  groupId,
  showHeaders = true,
}: RoomSettingsArticlesTableProps) => {
  const { storeNumber } = useParams();
  const roomId = articles[0]?.roomId;
  const { data: room } = useRoom(roomId);
  const [articleToUpdate, setArticleToUpdate] = React.useState<Article | undefined>(undefined);

  const { data: articlesInfo } = useArticlesInfo(
    articleToUpdate?.productArticleId ? [articleToUpdate.productArticleId] : [""]
  );

  const articleNumbers = articles
    .map((article) => article.productArticleId)
    .sort()
    .join(",");
  const { data: edsData } = useEDSData(storeNumber || "", articleNumbers);
  const { data: salesStopData } = useSalesStopData(storeNumber || "", articleNumbers);

  const { data: taskLinks } = useTaskLinksByRoom(roomId);

  React.useEffect(() => {
    const updateArticleWithData = async () => {
      if (!articlesInfo || !articleToUpdate) return;
      const quantity =
        articles.find((article) => article.productArticleId === articleToUpdate.productArticleId)?.nbrArticles || 1;
      const allArticles = transformArticlesInfos(
        roomId,
        [{ productArticleId: articleToUpdate.productArticleId, quantity } as ProductArticleIdAndQuantity],
        articlesInfo
      );
      if (allArticles.length > 0) {
        try {
          await updateArticle(articleToUpdate.id, { ...allArticles[0] });
          await articleUpdated(articleToUpdate.id, false, undefined);
        } catch (error) {
          console.log(error);
          await articleUpdated(articleToUpdate.id, false, "Could not update article. Please try again later.");
        }
      }
    };
    updateArticleWithData().catch(console.error);
  }, [articlesInfo, articleToUpdate]);

  const updateArticleButtonPressed = (article: Article) => {
    setArticleToUpdate(article);
  };

  const sortedArticleArray = sortArticles(
    articles,
    room?.room.main_article_id_1 || "",
    room?.room.main_article_id_2 || "",
    room?.room.backup_article_id_1 || "",
    room?.room.backup_article_id_2 || ""
  );

  return (
    <>
      <Table style={{ zIndex: 1 }} fullWidth inset>
        {showHeaders && <RoomSettingsArticlesTableHeader handleUpdateArticles={handleUpdateArticles} />}
        <TableBody style={{ backgroundColor: "white" }}>
          {sortedArticleArray
            .filter((article) => article.groupId === groupId)
            .map((article) => (
              <ArticleRow
                key={article.id}
                article={article}
                articleUpdated={articleUpdated}
                updateArticleButtonPressed={updateArticleButtonPressed}
                edsData={edsData?.find((eds) => eds.productArticleId === article.productArticleId) ?? null}
                salesStopData={
                  salesStopData?.find((salesStop) => salesStop.productArticleId === article.productArticleId) ?? null
                }
                taskLinks={taskLinks ?? []}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};
