import React, { useEffect, useState } from "react";
import Table from "@ingka/table";
import Select from "@ingka/select";
import { SkapaPromptModal, ModalOption } from "../../../modals/SkapaSheetModal";
import { CreateUserType } from "../types/users.types";
import { UserModalChildren, AllCountries, AllRoles, AllStoresByCountry } from "./users";
import { UsersTableLabels } from "../molecules/UsersTableLabels";
import { isUserCountrySuperUser, isUserGlobalSuperUser } from "../../../utils/roles.helpers";
import { useLoggedInUser } from "../hooks/useLoggedInUser";
import Search from "@ingka/search";
import { TableToolsHeader } from "../../layout/TableToolsHeader";
import { USER_ROLES, UserRole } from "../../../types/roles.types";
import { isChineseEnvironment } from "../../../services/shared.service";

export const UsersPage = () => {
  const { user } = useLoggedInUser();
  const [country, setCountry] = useState<string>("");
  const [store, setStore] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [userState, setUserState] = useState<CreateUserType>();
  const [isGSU, setIsGSU] = useState(false);
  const [isCSU, setIsCSU] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    setCountry(user.countryId);
    setStore(user.storeId);
    setUserState(user);
    setIsGSU(isUserGlobalSuperUser(user.roleId));
    setIsCSU(isUserCountrySuperUser(user.roleId));
  }, [user]);

  return (
    <>
      {userState && (
        <>
          <TableToolsHeader
            setShowTeamUsersTable={() => {}}
            showTeamUsersTable={false}
            selectedTeam={{ id: "", name: "" }}
            tableTitle="All users"
            headerControls={
              <SkapaPromptModal buttonText={"Add user"} description={""} option={ModalOption.ADD} onClose={() => {}}>
                <UserModalChildren
                  country={country}
                  user={userState}
                  setUserHandler={setUserState}
                  modalType={ModalOption.ADD}
                  setSuccessMessage={() => {}}
                />
              </SkapaPromptModal>
            }
            filtersLeft={
              <>
                {!isChineseEnvironment() && (
                  <Select
                    id={"countryselect"}
                    label={"Country"}
                    value={country}
                    children={<AllCountries />}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setStore("");
                      setCountry(event.target.value);
                    }}
                    disabled={!isGSU}
                  />
                )}
                <Select
                  id={"storeselect"}
                  label={"Store"}
                  children={<AllStoresByCountry country={country} />}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => setStore(event.target.value)}
                  disabled={!isCSU && !isGSU}
                />
                <Select
                  id={"roleselect"}
                  label={"Role"}
                  children={<AllRoles />}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    USER_ROLES.includes(event.target.value as UserRole) ? setRole(event.target.value) : setRole("")
                  }
                />
              </>
            }
            filtersRight={
              <>
                <div
                  style={{
                    backgroundColor: "red",
                    width: "100px",
                  }}
                ></div>

                <Search
                  id={"searchuser"}
                  type={"text"}
                  className={"search"}
                  placeholder="Find user by name"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value)}
                />
              </>
            }
          />
          <Table style={{ zIndex: 1 }} fullWidth inset>
            <UsersTableLabels country={country} store={store} role={role} searchValue={searchValue} onSort={() => {}} />
          </Table>
        </>
      )}
    </>
  );
};
