import React from "react";
import { useParams } from "react-router-dom";
import Table, { TableHeader } from "@ingka/table";
import { SwitchWrapper } from "../../../config/styles";
import { locales } from "../../../data/constants";
import useStore from "../hooks/useStore";
import useStoreContacts from "../../storeContacts/hooks/useStoreContacts";
import { CreateStoreContactForm } from "../../storeContacts/organisms/AddStoreContactForm";
import { StoreContactsData } from "../molecules/StoreContactsData";
import { SingleStoreData } from "../molecules/SingleStoreData";

export const StoreTable = () => {
  const { storeNumber } = useParams();
  const { data: storeContacts } = useStoreContacts(storeNumber || "");
  const { data: store } = useStore(storeNumber || "");
  const storeData = store?.data?.data;
  const storeCountry = locales.find((item) => item.isoCode === storeData?.country_id);

  return (
    <>
      {storeData && storeCountry && (
        <>
          <SwitchWrapper>
            <h1>
              {storeData.name}, {storeCountry.country}
            </h1>
            <CreateStoreContactForm />
          </SwitchWrapper>
          <Table style={{ width: "100%", margin: "auto" }}>
            <TableHeader sticky={true}>
              <tr>
                <th>Name </th>
                <th>Store number </th>
                <th>Active </th>
              </tr>
            </TableHeader>
            <SingleStoreData store={storeData} />
          </Table>

          {storeContacts && storeContacts.length > 0 && (
            <>
              <h2 style={{ marginTop: "60px" }}>Store contacts</h2>
              <Table style={{ marginTop: "20px" }}>
                <TableHeader sticky={true}>
                  <tr>
                    <th>Team ID </th>
                    <th>Phone name </th>
                    <th>Phone number </th>
                  </tr>
                </TableHeader>
                <StoreContactsData storeContacts={storeContacts} />
              </Table>
            </>
          )}
        </>
      )}
    </>
  );
};
