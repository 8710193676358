import React, { useState, useEffect } from "react";
import Table from "@ingka/table";
import Select from "@ingka/select";
import { CreateStoreContactForm } from "./AddStoreContactForm";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import { AllStoresByCountry } from "../../users/organisms/users";
import { StoreContactsTableHeader } from "../molecules/StoreContactsTableHeader";
import { StoreContactsBody } from "../molecules/StoreContactsBody";
import { isUserCountrySuperUser, isUserGlobalSuperUser } from "../../../utils/roles.helpers";
import { TableToolsHeader } from "../../layout/TableToolsHeader";

export const StoreContactsTable = () => {
  const { user } = useLoggedInUser();
  const [storeId, setStoreId] = useState("");
  const countryId = user?.countryId ?? "";
  const isGSU = user ? isUserGlobalSuperUser(user.roleId) : false;
  const isCSU = user ? isUserCountrySuperUser(user.roleId) : false;

  useEffect(() => {
    if (user) {
      setStoreId(user?.storeId);
    }
  }, [user]);

  return (
    <>
      <TableToolsHeader
        setShowTeamUsersTable={() => {}}
        showTeamUsersTable={false}
        selectedTeam={{ id: "", name: "" }}
        tableTitle="All store contacts"
        headerControls={<CreateStoreContactForm />}
        filtersLeft={
          <>
            <Select
              id={"country-select"}
              label={"Store"}
              value={storeId}
              children={<AllStoresByCountry country={countryId} />}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setStoreId(event.target.value);
              }}
              disabled={!isCSU && !isGSU}
            />
          </>
        }
      />

      <Table style={{ zIndex: 1 }} fullWidth inset>
        <StoreContactsTableHeader />
        <StoreContactsBody storeId={storeId} />
      </Table>
    </>
  );
};
