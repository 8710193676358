import React, { useEffect, useState } from "react";
import Button from "@ingka/button";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import Select, { Option } from "@ingka/select";
import InputField from "@ingka/input-field";
import { ModalItemWrapper } from "../../../config/styles";
import { useCreateStoreContact } from "../hooks/useCreateStoreContact";
import { CreateStoreContactData } from "../types/StoreContacts.types";
import useStoresByCountryId from "../../stores/hooks/useStoresByCountryId";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import { TeamsByStoreId } from "../atoms/TeamsByStoreId";
import { isUserGlobalSuperUser, isUserCountrySuperUser } from "../../../utils/roles.helpers";
import { Message } from "../../teams/organisms/UpdateTeamForm";
import useStoreContacts from "../hooks/useStoreContacts";

export const CreateStoreContactForm = () => {
  const { user } = useLoggedInUser();
  const { data: stores } = useStoresByCountryId(user?.countryId);
  const { data: storeContacts } = useStoreContacts(user?.storeId ?? "");
  const [modalVisibility, setModalVisibility] = useState(false);
  const {
    mutateAsync: createStoreContactMutation,
    isSuccess,
    isPending,
    isError,
    error,
    reset,
  } = useCreateStoreContact();

  const [storeContact, setStoreContact] = useState<CreateStoreContactData>({
    storeId: user?.storeId ?? "",
    teamId: user?.teamId ?? "",
    countryId: "",
    phoneName: "",
    phoneNumber: "",
  });
  const [isGSU, setIsGSU] = useState(false);
  const [isCSU, setIsCSU] = useState(false);
  const [storeContactDuplicateErrorMessage, setStoreContactDuplicateErrorMessage] = useState<string | null>(null);

  const resetMutationState = () => {
    reset();
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    setIsGSU(isUserGlobalSuperUser(user.roleId));
    setIsCSU(isUserCountrySuperUser(user.roleId));
  }, [user]);

  const handleSubmit = async () => {
    if (storeContact && stores) {
      const foundStore = stores?.find((store) => store.storeId === storeContact.storeId);
      const storeContactToAdd: CreateStoreContactData = {
        ...storeContact,
        countryId: foundStore?.countryId || "",
      };

      const storeContactExists = storeContacts?.some(
        (existingContact) => existingContact.name === storeContactToAdd.phoneName
      );
      if (storeContactExists) {
        setStoreContactDuplicateErrorMessage(
          "A store contact with this name already exists, please choose another name."
        );
        return;
      }
      try {
        await createStoreContactMutation(storeContactToAdd);
        setStoreContact({
          storeId: "",
          teamId: "",
          countryId: "",
          phoneName: "",
          phoneNumber: "",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <>
      <Button type="emphasised" onClick={() => setModalVisibility(!modalVisibility)}>
        Add store contact
      </Button>

      <Modal
        visible={modalVisibility}
        handleCloseBtn={() => {
          setModalVisibility(false);
          resetMutationState();
          setStoreContactDuplicateErrorMessage(null);
        }}
      >
        <Prompt
          titleId="add-store-contact"
          title="Add store contact"
          header={<ModalHeader ariaCloseTxt="Close prompt." />}
          footer={
            <>
              <ModalFooter>
                <Button type="primary" fluid onClick={handleSubmit} loading={isPending}>
                  Add store contact
                </Button>
              </ModalFooter>
            </>
          }
        >
          <ModalItemWrapper>
            <Select
              id={"storeId"}
              label={"Store ID"}
              hintText={"Select a store"}
              value={storeContact.storeId}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setStoreContact((storeContact) => ({
                  ...storeContact,
                  storeId: event.target.value,
                }));
              }}
              disabled={!isGSU && !isCSU}
            >
              {stores?.map((store) => <Option key={store.storeId} value={store.storeId} name={store.name} />)}
            </Select>
            <Select
              id={"teamId"}
              label={"Team ID"}
              hintText={"Select a team"}
              value={storeContact.teamId}
              children={<TeamsByStoreId storeId={storeContact.storeId} />}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setStoreContact((storeContact) => ({
                  ...storeContact,
                  teamId: event.target.value,
                }));
              }}
            />

            <InputField
              id={"phoneName"}
              type={"text"}
              label={"Enter contact name"}
              value={storeContact.phoneName}
              onChange={(event) => {
                setStoreContact((storeContact) => ({
                  ...storeContact,
                  phoneName: event.target.value,
                }));
              }}
            />
            <InputField
              id={"phoneNumber"}
              type={"text"}
              label={"Enter phone number"}
              value={storeContact.phoneNumber}
              onChange={(event) => {
                setStoreContact((storeContact) => ({
                  ...storeContact,
                  phoneNumber: event.target.value,
                }));
              }}
            />
            {isSuccess && <Message color="green">Store contact added successfully</Message>}
            {isError && <Message color="red">{error.message}</Message>}
            {storeContactDuplicateErrorMessage && <Message color="red">{storeContactDuplicateErrorMessage}</Message>}
          </ModalItemWrapper>
        </Prompt>
      </Modal>
    </>
  );
};
