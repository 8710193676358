import React, { useState, useEffect } from "react";
import Button from "@ingka/button";
import InputField from "@ingka/input-field";
import { ModalItemWrapper } from "../../../config/styles";
import Select, { Option } from "@ingka/select";
import useUpdateTeam from "../hooks/useUpdateTeam";
import useTeam from "../hooks/useTeam";
import { useNavigate } from "react-router-dom";
import useStoresByCountryId from "../../stores/hooks/useStoresByCountryId";
import { styled } from "styled-components";
import { useDeleteTeam } from "../hooks/useDeleteTeam";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import { ConfirmCancelModal } from "../../../modals/ConfirmCancelModal";
import Modal from "@ingka/modal";
import { ModalOption, SkapaPromptModal } from "../../../modals/SkapaSheetModal";
import useGetTeamsByStoreId from "../hooks/useGetTeamsByStoreId";
import { checkForPqrTeamInStore } from "../../../helpers/helpers";

interface UpdateTeamFormProps {
  teamId: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const Underlined = styled.p`
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
`;

export const Message = styled.p<{ color: string }>`
  width: fit-content;
  padding: 16px 0 0 0;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const UpdateTeamForm = ({ teamId, onClose, setSuccessMessage }: UpdateTeamFormProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data } = useTeam(teamId);
  const { user } = useLoggedInUser();
  const { data: stores } = useStoresByCountryId(user?.countryId);
  const { mutateAsync: updateTeamMutation, isSuccess, isPending, isError, error } = useUpdateTeam();
  const deleteTeamMutation = useDeleteTeam(teamId);
  const navigate = useNavigate();
  const { data: allTeamsInStore } = useGetTeamsByStoreId(data?.storeId || "");
  const hasPqrInStore = checkForPqrTeamInStore(allTeamsInStore ?? []);
  const [team, setTeam] = useState({
    team_id: "",
    status: "",
    store_id: "",
    name: "",
    is_product_quality: data?.is_product_quality || false,
  });

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target !== null) {
      setTeam({
        ...team,
        is_product_quality: event.target.value === "true",
      });
    }
  };

  const handleDelete = async () => {
    await deleteTeamMutation.mutateAsync();
    setIsModalVisible(false);
    setSuccessMessage(`Team: ${team.name}, successfully deleted`);
    onClose(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    try {
      await updateTeamMutation(team);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (data && stores) {
      const selectedStore = stores.find((store) => store.storeId === data.storeId);
      setTeam((prevTeam) => ({
        ...prevTeam,
        team_id: data.id || "",
        status: data.status || "",
        store_id: selectedStore?.storeId ?? "",
        name: data.name || "",
        is_product_quality: data.is_product_quality || team.is_product_quality,
        created_at: Date.now(),
        updated_at: Date.now(),
      }));
    }
  }, [data, stores, setTeam]);

  return (
    <Modal
      focusLockProps={{
        locked: false,
      }}
      visible={true}
      keepContentMounted={true}
      handleCloseBtn={() => {
        navigate(-1);
      }}
    >
      <SkapaPromptModal
        buttonText={`Edit team: ${team.name}`}
        description=""
        option={ModalOption.EDIT}
        onClose={() => onClose(false)}
      >
        <ModalItemWrapper>
          <InputField
            id={"name"}
            type={"text"}
            label={"Enter team name"}
            value={team.name}
            onChange={(event) => {
              setTeam({
                ...team,
                name: event.target.value,
              });
            }}
          />
          {!hasPqrInStore && (
            <Select
              id={"product_quality"}
              label={"Product quality"}
              hintText={"Set product quality"}
              value={String(team.is_product_quality)}
              onChange={handleSelectChange}
            >
              <Option value={"true"} key={"true"} name={"Yes"} />
              <Option value={"false"} key={"false"} name={"No"} />
            </Select>
          )}
          {isSuccess && <Message color="green">{"Team updated successfully"}</Message>}
          {isError && <Message color="red">{error.message}</Message>}
          <Button
            type="danger"
            fluid={false}
            style={{ backgroundColor: "#e00751", color: "#fff", float: "right" }}
            onClick={() => {
              setIsModalVisible(true);
            }}
          >
            Delete Team
          </Button>
          <ConfirmCancelModal
            header="Confirm Delete"
            title={`Are you sure you want to delete team: ${team.name}?`}
            message={
              <>
                All of the tasks assigned to the team will be <strong>deleted</strong>.
                <br />
                This action cannot be undone.
              </>
            }
            isVisible={isModalVisible}
            onOK={handleDelete}
            onCancel={handleCancel}
          />
          <Button type="primary" fluid onClick={handleSubmit} loading={isPending}>
            Update Team
          </Button>
        </ModalItemWrapper>
      </SkapaPromptModal>
    </Modal>
  );
};
