import React, { useEffect, useState } from "react";
import Button from "@ingka/button";
import Modal, { Prompt, ModalFooter, ModalHeader } from "@ingka/modal";
import InputField from "@ingka/input-field";
import Switch from "@ingka/switch";
import { ModalItemWrapper } from "../../../config/styles";
import useCreateTeam from "../hooks/useCreateTeam";
import { CreateNewTeam } from "../types/Teams.types";
import Select, { Option } from "@ingka/select";
import useTeam from "../hooks/useTeam";
import useStoresByCountryId from "../../stores/hooks/useStoresByCountryId";
import { styled } from "styled-components";
import { useLoggedInUser } from "../../users/hooks/useLoggedInUser";
import { isUserGlobalSuperUser, isUserCountrySuperUser } from "../../../utils/roles.helpers";
import useGetTeamsByStoreId from "../hooks/useGetTeamsByStoreId";
import { checkForPqrTeamInStore } from "../../../helpers/helpers";

interface AddTeamFormProps {
  teamId: string;
}

export const Message = styled.p<{ color: string }>`
  width: fit-content;
  padding: 16px 0 0 0;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const AddTeamForm = ({ teamId }: AddTeamFormProps) => {
  const { user } = useLoggedInUser();
  const { data: currentTeam } = useTeam(teamId);
  const { data: stores } = useStoresByCountryId(user?.countryId);
  const { mutateAsync: createTeamMutation, isSuccess, isPending, isError, error, reset } = useCreateTeam();
  const [modalVisibility, setModalVisibility] = useState(false);
  const [productQuality, setProductQuality] = useState(false);
  const [status, setStatus] = useState(false);
  const [hasPqrInStore, setHasPqrInStore] = useState(false);
  const [team, setTeam] = useState<CreateNewTeam>({
    status: "",
    store_id: user?.storeId ?? "",
    name: "",
    is_product_quality: productQuality,
    created_at: 0,
  });
  const { data: teamsByStore } = useGetTeamsByStoreId(team.store_id);
  const [isGSU, setIsGSU] = useState(false);
  const [isCSU, setIsCSU] = useState(false);
  const [teamNameDuplicateErrorMessage, setTeamNameDuplicateErrorMessage] = useState<string | null>(null);

  React.useEffect(() => {
    if (teamsByStore) {
      const hasPqrInStore = checkForPqrTeamInStore(teamsByStore);
      setHasPqrInStore(hasPqrInStore);
    }
  }, [teamsByStore]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setIsGSU(isUserGlobalSuperUser(user.roleId));
    setIsCSU(isUserCountrySuperUser(user.roleId));
  }, [user]);

  const resetMutationState = () => {
    reset();
  };

  const handleSubmit = async () => {
    if (currentTeam && stores) {
      const teamToBeAdded: CreateNewTeam = {
        ...team,
        store_id: team.store_id ?? user?.storeId ?? "",
        status: status === true ? "ACTIVE" : "INACTIVE",
        is_product_quality: productQuality,
        created_at: Date.now(),
      };

      const teamNameExists = teamsByStore?.some((existingTeam) => existingTeam.name === teamToBeAdded.name);
      if (teamNameExists) {
        setTeamNameDuplicateErrorMessage(
          "A team with this name already exists in the store, please choose another name."
        );
        return;
      }

      try {
        await createTeamMutation(teamToBeAdded);
        setTeam({
          ...team,
          name: "",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Button type="emphasised" onClick={() => setModalVisibility(!modalVisibility)}>
        Add team
      </Button>

      <Modal
        visible={modalVisibility}
        handleCloseBtn={() => {
          setModalVisibility(false);
          resetMutationState();
          setTeamNameDuplicateErrorMessage(null);
        }}
      >
        <Prompt
          title="Add team"
          titleId="add-team"
          header={<ModalHeader ariaCloseTxt="Close prompt." />}
          footer={
            <>
              <ModalFooter>
                <Button type="primary" fluid onClick={handleSubmit} loading={isPending}>
                  Add team
                </Button>
              </ModalFooter>
            </>
          }
        >
          <ModalItemWrapper>
            <Select
              id={"store_id"}
              label={"Store"}
              hintText={"Select a store"}
              value={team.store_id}
              children={stores?.map((store) => <Option key={store.storeId} value={store.storeId} name={store.name} />)}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedStoreId = event.target.value;
                setTeam((store) => ({
                  ...store,
                  store_id: selectedStoreId,
                }));
              }}
              disabled={!isCSU && !isGSU}
            />
            <InputField
              id={"name"}
              type={"text"}
              label={"Enter team name"}
              value={team.name}
              onChange={(event) => {
                setTeam((team) => ({
                  ...team,
                  name: event.target.value,
                }));
              }}
            />
            <Switch id="status" value={String(status)} label={"Status"} onClick={() => setStatus(!status)} />

            {!hasPqrInStore && (
              <Switch
                id="is_product_quality"
                value={String(productQuality)}
                label={"Product quality"}
                onClick={() => {
                  setProductQuality(!productQuality);
                }}
              />
            )}
            {isSuccess && <Message color="green">Team added successfully</Message>}
            {isError && <Message color="red">{error.message}</Message>}
            {teamNameDuplicateErrorMessage && <Message color="red">{teamNameDuplicateErrorMessage}</Message>}
          </ModalItemWrapper>
        </Prompt>
      </Modal>
    </>
  );
};
